import { createFeatureSelector, createSelector } from "@ngrx/store";
import { GlobalState } from "./global.reducer";

export const getGlobalState = createFeatureSelector<GlobalState>("global");

export const selectPageTitle = createSelector(
  getGlobalState,
  (state) => state.pageTitle
);

export const selectActiveLanguage = createSelector(
  getGlobalState,
  (state) => state.activeLanguage
);

export const selectPageType = createSelector(
  getGlobalState,
  (state) => state.pageType
);

export const selectPageId = createSelector(
  getGlobalState,
  (state) => state.pageId
);

export const selectBusinessUnit = createSelector(
  getGlobalState,
  (state) => state.businessUnit
);

export const selectArticleFilterCategory = createSelector(
  getGlobalState,
  (state) => state.filterCategory
);

export const selectSearchType = createSelector(
  getGlobalState,
  (state) => state.pageSearchType
);
export const selectBaseHref = createSelector(
  getGlobalState,
  (state) => state.baseHref
);

export const selectInPageNav = createSelector(
  getGlobalState,
  (state) => state.inPageNav
);

export const selectYoutubeScriptLoaded = createSelector(
  getGlobalState,
  (state) => state.youtubeScriptLoaded
);

export const selectTrustPilot = createSelector(
  getGlobalState,
  (state) => state.trustPilot
);

export const selectAudienceNavigation = createSelector(
  getGlobalState,
  (state) => state.audienceNavigation
);

export const selectMainMobileNavOpen = createSelector(
  getGlobalState,
  (state) => state.isMainNavMobileOpen
);

export const selectMainNavOpen = createSelector(
  getGlobalState,
  (state) => state.isMainNavOpen
);

export const selectWindowLoaded = createSelector(
  getGlobalState,
  (state) => state.windowLoaded
);

export const selectInLineContentIdList = createSelector(
  getGlobalState,
  (state) => state.inLineContentIdList
);
