import { createAction, props } from "@ngrx/store";

export const loadResults = createAction(
  "[Search results] Load results",
  props<{
    query: string;
    collection: string;
    page: number;
    filters: any;
  }>()
);

export const loadResultsSuccess = createAction(
  "[Search results] Load results success",
  props<{
    search: any;
    searchTerm: string;
    collection: string;
    page: number;
    filters: any;
    totalMatching: number;
  }>()
);

export const setSearchTerm = createAction(
  "[Search results] Set search term",
  props<{ searchTerm: string }>()
);

export const setSearchResultPage = createAction(
  "[Search results] Set search result page",
  props<{ searchResultPage: string }>()
);

export const setCollectionName = createAction(
  "[Search results] Set collection name",
  props<{ collectionName: string }>()
);

export const updateTotalMatching = createAction(
  "[Search results] Update Total Matching",
  props<{ total: number }>()
);

export const setSelectedFilters = createAction(
  "[Search results] Set filters",
  props<{ selectedFilters: any }>()
);
