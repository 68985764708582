import { ApplicationConfig } from "@angular/core";
import {
  provideRouter,
  UrlSerializer,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling
} from "@angular/router";

import { routes } from "./app.routes";
import { provideClientHydration } from "@angular/platform-browser";
import { provideStore } from "@ngrx/store";
import { globalReducer } from "./store/global/global.reducer";
import { searchReducer } from "./store/search/search.reducer";
import { provideHttpClient, withFetch } from "@angular/common/http";
import { provideEffects } from "@ngrx/effects";
import { SearchEffects } from "./store/search/search.effects";
import { CustomUrlSerializer } from "./serializer/CustomUrlSerielizer";
import { DecimalPipe } from "@angular/common";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled"
      }),
      withEnabledBlockingInitialNavigation()
    ),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideStore({
      global: globalReducer,
      search: searchReducer
    }),
    provideEffects([SearchEffects]),
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    DecimalPipe
  ]
};
